import Link from 'next/link';
import React from 'react';
import Icon from 'components/ui/Icon/Icon';
import SignIn, { getSignInProps, SignInProps } from 'components/ui/SignIn';
import { GetStaticProps } from 'next';

export default function Login(props: SignInProps) {
  return (
    <div className="flex items-center justify-center flex-auto">
      <div className="flex-none stack-8">
        <h1 className="font-serif text-5xl text-center stack-2">
          Welcome back!
        </h1>
        <SignIn {...props} />
        <Link href="/signup">
          <a className="items-center justify-start text-gray-500 row-1 hover:underline">
            Don't have an account yet? Sign up instead{' '}
            <Icon name="arrowRight" />
          </a>
        </Link>
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async function getStaticProps() {
  return getSignInProps();
};
